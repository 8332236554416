import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CommonPermission, CoreModule, CanDeactivateGuard, ModalService } from '../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from 'ng2-semantic-ui';

// Services
import { ContactsService } from './services/contacts';

// Pages
import { ContactListPageComponent } from './pages/contact-list';
import { ContactDetailPageComponent } from './pages/contact-detail';

//Components
import { PlacesOfBusinessComponent } from './components/contact-places-of-business';

//Modals
import { PlaceOfBusinessModalComponent } from './modals/place-of-business';
import { ContactAddModalComponent } from './modals/contact-add';


// Routes
const routes: Routes = [
    {
        path: 'contacts',
        children: [
            { path: '', component: ContactListPageComponent },
            { path: ':id', component: ContactDetailPageComponent, canDeactivate: [CanDeactivateGuard]}
        ],
        data: {
            appScope: 'Contact'
        }
    }
]

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        RouterModule.forChild(routes),
        FormsModule,
        SuiModule
    ],
    declarations: [
        ContactListPageComponent,
        ContactDetailPageComponent,
        PlacesOfBusinessComponent,
        PlaceOfBusinessModalComponent,
        ContactAddModalComponent
    ],
    exports: [
        RouterModule
    ],
    providers: [
		ContactsService,
		// Musí být v každém modulu, který ji využívá
		// LazyLoaded entry components
		ModalService
    ],
    entryComponents: [
        PlaceOfBusinessModalComponent,
        ContactAddModalComponent
    ]
})
export class ContactsModule { }