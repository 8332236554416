import { OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormComponentBase } from '../classes/form-component-base';
import { UIService } from '../services/ui';
import { HelperService } from '../services/helper';
import { AuthService } from '../services/auth';
import { AppScopeService } from '../services/app-scope';
import { IModelService } from '../services/model-service';

/**
 * Bázová třída pro komponenty reprezentující editační formuláře načítající data
 * z ModelServiceBase.
 */
export abstract class ModelFormComponentBase extends FormComponentBase implements OnInit {

	/**
	 * Hostitel pro form. Importovaný nebo default
	 */
	@Input() host: IModelFormHost;

	constructor(
		protected _activatedRoute: ActivatedRoute,
		protected _uiService: UIService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
		protected _appScopeService: AppScopeService,
		protected _modelService: IModelService,
		protected _router: Router = null) {

		super(_uiService, _helperService, _authService, _appScopeService)
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		if (!this.host) {
			this.host = new DefaultModelFormHost(this._uiService, this._router, this._activatedRoute);
		}

		this.host.onBeforeLoaded();

		this.modelId = this.host.getModelId();

		// Kontrola id-čka
		if (isNaN(this.modelId)) {
			this.host.onLoadingError();

			throw 'Model form host doesnt returned valid model id.';
		}

		// Načítám data 
		this._modelService.get<any>(this.modelId, this.getApiCustomQuery()).then(model => {
			if (model != null) {
				this.onModelLoaded(model);

				super.setModel(this.modelId, model);
			}

			this.host.onAfterLoaded();
		});
	}

	/**
	 * Kliknutí na tlačítko Zpět 
	 */
	protected back(): void {
		this.host.onBackClicked();
	}

	/**
	 * Umožňuje dědícím třídám dodat vlastní query string který bude posílán do API
	 * v metofách get a set
	 */
	protected getApiCustomQuery(): any {
		return null;
	}

	/**
	 * Umožňuje dědícím třídám provést operaci ve chvíli načtení modelu z API.
	 *
	 * @param model
	 */
	protected onModelLoaded(model: any) {
	}

	/**
	 * Umožňuje dědícím třídám provést operaci ve chvíli uložení modelu.
	 *
	 * @param model
	 */
	protected onModelSaved(model: any) {
	}

	/**
	 * Provede uložení
	 */
	save() {
		this.host.onBeforeSaved();

		this._modelService.set(this.modelId, this.model, this.getApiCustomQuery()).then(
			model => {
				if (model != null) {
					this.onModelSaved(model);

					super.setModel(model.id, model);
				}

				this.host.onAfterSaved();
			});
	}

	/**
	 * Provede uložení a prechod na seznam
	 */
	saveWithClose() {
		this.host.onBeforeSaved();

		this._modelService.set(this.modelId, this.model, this.getApiCustomQuery()).then(
			model => {
				if (model != null) {
					this.onModelSaved(model);

					super.setModel(model.id, model);

					this._router.navigate(['../'], { relativeTo: this._activatedRoute });
				}

				this.host.onAfterSaved();
			});
	}

	/**
	 * Provede uložení a prechod na dalsi zaznam
	 */
	saveWithNew() {
		this.host.onBeforeSaved();

		this._modelService.set(this.modelId, this.model, this.getApiCustomQuery()).then(
			model => {
				if (model != null) {

					this.modelId = 0;

					this._modelService.get<any>(this.modelId, this.getApiCustomQuery()).then(model => {
						if (model != null) {
							this.onModelLoaded(model);

							super.setModel(this.modelId, model);
						}

						this.host.onAfterLoaded();
					});

				}

			});
	}

}

export interface IModelFormHost {
	getModelId(): number;
	onBeforeLoaded(): void;
	onAfterLoaded(): void;
	onLoadingError(): void;
	onBeforeSaved(): void;
	onAfterSaved(): void;
	onBackClicked(): void;
}

class DefaultModelFormHost implements IModelFormHost {
	constructor(
		private _uiService: UIService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute) {
	}

	public getModelId(): number {
		return parseInt(this._activatedRoute.snapshot.params['id']);
	}

	public onBeforeLoaded(): void {
		this._uiService.showLoader();
	}

	public onAfterLoaded(): void {
		this._uiService.hideLoader();
	}

	public onLoadingError(): void {
		this._uiService.hideLoader();
	}

	public onBeforeSaved(): void {
		this._uiService.showLoader();
	}

	public onAfterSaved(): void {
		this._uiService.hideLoader();
	}

	public onBackClicked(): void {
		this._router.navigate(['../'], { relativeTo: this._activatedRoute });
	}
}

export class ModalModelFormHost implements IModelFormHost {

	public working: boolean = false;

	constructor(
		private _id: number,
		private _onBack: () => void,
		private _onSaved: () => void) {
	}

	public getModelId(): number {
		return this._id;
	}

	public onBeforeLoaded(): void {
		this.working = true;
	}

	public onAfterLoaded(): void {
		this.working = false;
	}

	public onLoadingError(): void {
		this.working = false;
	}

	public onBeforeSaved(): void {
		this.working = true;
	}

	public onAfterSaved(): void {
		this.working = false;
		this._onSaved();
	}

	public onBackClicked(): void {
		this._onBack();
	}
}