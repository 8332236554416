import { Injectable, Inject } from '@angular/core';
import { ApiService } from './api';
import { ScopePermissionType } from '../models';
import { CurrentUserInfo, CommonPermission } from '../models';
import { concat } from 'rxjs';

/**
 * Služba poskytující autentizační funkcionalitu.
 * 
 * @export
 * @class AuthService
 */
@Injectable()
export class AuthService {
    currentUser: CurrentUserInfo = null;

	constructor(
		private _apiService: ApiService) {
	}

    /**
     * Inicializace - volána při startu aplikace
     */
	initialize() {
        return new Promise((resolve, reject) => {
            // Pokud mame token (z cookie), pokusime se nacist user info
			if (this._apiService.token) {
				this._apiService.get<CurrentUserInfo>('auth/userinfo').then(
					result => {
						if (result == null) {
							this.logOut();
						}
						else {
							this.currentUser = result;
						}

						resolve();
					},
					error => {
						this.logOut();
						resolve();
					});
            }
            else {
                resolve();
            }
        });
    }

    /**
     * Provede odhlášení
     */
    logOut() {
        this._apiService.get<void>('auth/logout');
		this._apiService.token = null;
        this.currentUser = null;
    }

	/**
	 * Vrátí informaci jestli má aktuální uživatel povoleno oprávnění
	 *
	 * @param permission
	 */
	hasCommonPermission(permission: CommonPermission): boolean {
		if (!this.currentUser) {
			return false;
		}

		return !!(this.currentUser.commonPermissions & permission);
	}

    /**
     * Provede přihlášení přes API a vrtátí výsledek a popř. chybovou hlášku.
	 *
     * @param {string} username 
     * @param {string} password 
     * @returns {Promise<SignInResult>} 
     */
    signIn(username: string, password: string): Promise<SignInResult> {
        return new Promise((resolve, reject) => {
            let request: SignInRequest = {
                username: username,
                password: password
            };

			this._apiService.post<SignInResponse>('auth/signin', request).then(result => {
                if (result.success) {
                    // Nastavíme token, který jsme dostali
					this._apiService.token = result.token;

                    let needChangePassword = result.needChangePassword;

                    // Load current user
					this._apiService.get<CurrentUserInfo>('auth/userinfo').then(result => {
                        this.currentUser = result;

                        resolve({
                            success: true,
                            message: null,
                            needChangePassword: needChangePassword
                        });
					});
                }
                else {
                    resolve({
                        success: result.success,
                        message: result.message,
                        needChangePassword: false
                    });
                }
            });
        });
    }

    /**
   * Provede zmenu hesla přes API a vrtátí výsledek a popř. chybovou hlášku.
   *
   * @param {string} password
   * @param {string} passwordConfirmation 
   * @returns {Promise<SignInResult>} 
   */
    passwordReset(lostPassIdent: string, userGuid: string, password: string, passwordConfirmation: string): Promise<PasswordResetResponse> {
        return new Promise((resolve, reject) => {
            let request: PasswordResetRequest = {
                lostPassIdent: lostPassIdent,
                userGuid: userGuid,
                password: password,
                passwordConfirmation: passwordConfirmation
            };

            this._apiService.post<PasswordResetResponse>('auth/passwordReset', request).then(result => {
                if (result.success) {
                    resolve({
                        success: result.success,
                        message: result.message
                    });
                }
                else {
                    resolve({
                        success: result.success,
                        message: result.message
                    });
                }
            });
        });
    }

    passwordChange(password: string, passwordConfirmation: string, oldPassword: string = ""): Promise<PasswordChangeResponse> {
        return new Promise((resolve, reject) => {
            let request: PasswordChangeRequest = {
                password: password,
                passwordConfirmation: passwordConfirmation,
                oldPassword: oldPassword
            };

            this._apiService.post<PasswordChangeResponse>('auth/passwordChange', request).then(result => {
                if (result.success) {
                    resolve({
                        success: result.success,
                        message: result.message
                    });
                }
                else {
                    resolve({
                        success: result.success,
                        message: result.message
                    });
                }
            });
        });
    }

	/**
	 * Vrátí informaci, jestli má aktuální uživatel alespoň jedno s požadovaných oprávnění na specifikovaný aplikační scope
	 *
	 * @param appScope Cesta k aplikačnímu scopu. Např. 'Portal.Commission'
	 * @param permissionType Požadované oprávnění jako flagy. Např. ScopePermissionType.Create | ScopePermissionType.Update
	 */
    hasAnyAppScopePermission(appScope: string, permissionType: ScopePermissionType, appScopeType: string = ""): boolean {

        // Doplnil TOM, padalo to na chybe, kdy appScope byl null
        if (appScope == null) {
            return true;
        }

		if (!this.currentUser) {
			return false;
		}


        let scopePermission;

        
        if (appScopeType == "navbar") {

            // V pripadne navbaru (horni menu) je potreba aplikovat konkretni prava
            scopePermission =   this.currentUser.scopePermissions
                .filter(x => appScope.toLowerCase() == x.scopePath.toLowerCase() && (x.permissionType == permissionType || x.permissionType == ScopePermissionType.All))
                .sort((x, y) => y.scopePath.length - x.scopePath.length) // seřadíme sestupně podle délky cesty
            [0];


        } else {

            //console.log(appScope, permissionType);
            //console.log(permissionType);
            

            //if (appScope == "Lists.Iris") {
            //    console.log(scopePermission = this.currentUser.scopePermissions
            //        .filter(x => appScope.toLowerCase().startsWith(x.scopePath.toLowerCase()) && (x.permissionType == permissionType || x.permissionType == ScopePermissionType.All)));

            //    console.log(scopePermission = this.currentUser.scopePermissions
            //        .filter(x => appScope.toLowerCase().startsWith(x.scopePath.toLowerCase()) && (x.permissionType == permissionType || x.permissionType == ScopePermissionType.All))[0]);

            //    console.log(this.currentUser.scopePermissions
            //        .filter(x => appScope.toLowerCase().startsWith(x.scopePath.toLowerCase()) && (x.permissionType == permissionType || x.permissionType == ScopePermissionType.All))
            //        .sort((x, y) => y.scopePath.length - x.scopePath.length) // seřadíme sestupně podle délky cesty
            //    [0]);

            //    console.log(this.currentUser.scopePermissions);
            //}

            //console.log(appScope, this.currentUser.scopePermissions, this.currentUser.scopePermissions.filter(x => appScope.toLowerCase().startsWith(x.scopePath.toLowerCase()) && (x.permissionType == permissionType || x.permissionType == ScopePermissionType.All)));

            // Najdeme definici práv pro nejbližší možný definovaný aplikační scope
            scopePermission = this.currentUser.scopePermissions
                .filter(x => appScope.toLowerCase().startsWith(x.scopePath.toLowerCase()) && (x.permissionType == permissionType || x.permissionType == ScopePermissionType.All))
                .sort((x, y) => y.scopePath.length - x.scopePath.length) // seřadíme sestupně podle délky cesty
            [0];

            //console.log(appScope, permissionType);
            //console.log(scopePermission.permissionType);
        }

        if (!scopePermission) {
			// Pokud jsme nenašli odpovídající definici práv - false
			return false;
		}

		return (scopePermission.permissionType & permissionType) != 0;
	}
}

export class SignInResult {
    success: boolean;
    message: string;
    needChangePassword: boolean;
}



// API modely

class SignInRequest {
    username: string;
    password: string;
}

class SignInResponse {
    success: boolean;
    message: string;
    token: string;
    needChangePassword: boolean;
}
class PasswordResetRequest {
    lostPassIdent: string;
    userGuid: string;
    password: string;
    passwordConfirmation: string;
}
class PasswordResetResponse {
    success: boolean;
    message: string;
}

class PasswordChangeRequest {
    password: string;
    passwordConfirmation: string;
    oldPassword: string;
}
class PasswordChangeResponse {
    success: boolean;
    message: string;
}