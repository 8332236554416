import { Component, ViewChild, ElementRef } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';

@Component({
	templateUrl: "/template/core/modals/message.cshtml"
})
export class MessageModalComponent {
	/*
	 * Reference na text area
	 */
	@ViewChild('aaa', { static: false }) textArea: ElementRef;

	constructor(
		public modal: SuiModal<IMessageModalContext, void>) {
	}

	public canShowActionButtons(): boolean {		
		return this.modal.context.showActions;
	}

	/**
	 * Zkopíruje text v textarea do schránky 
	 **/
	public copyToClipboard(): void {
		this.textArea.nativeElement.focus();
		this.textArea.nativeElement.select();

		document.execCommand('copy');
	}
}

export class MessageModal extends ComponentModalConfig<IMessageModalContext, void, void> {

	constructor(title: string, message: string, showActions = true, modalType: MessageModalType = MessageModalType.Html) {
		super(MessageModalComponent, { title, message, showActions, modalType });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Small;
	}
}

export enum MessageModalType {
	Html = 0,
	TextArea = 1
}

/**
 * Rozhraní pro kontext message modalu
 */
interface IMessageModalContext {
	title: string;
	message: string;
	showActions?: boolean;
	modalType: MessageModalType;
}