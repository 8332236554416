import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from '../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from 'ng2-semantic-ui';

//// Services
import { TransportCarrierCollectionService } from './services/transportcarriercollection';

//// Pages
import { TransportCarrierCollectionListPageComponent } from './pages/transportcarriercollection-list';
import { TransportCarrierCollectionDetailPageComponent } from './pages/transportcarriercollection-detail'

// Routes
const routes: Routes = [
    {
        path: 'transportcarriercollection',
        children: [
            { path: '', component: TransportCarrierCollectionListPageComponent },
            { path: ':id', component: TransportCarrierCollectionDetailPageComponent }
        ],
        data: {
            appScope: 'Logistics.TransportCarrierCollection'
        }
    }
];

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        RouterModule.forChild(routes),
        FormsModule,
        SuiModule
    ],
    declarations: [
        TransportCarrierCollectionListPageComponent,
        TransportCarrierCollectionDetailPageComponent
    ],
    providers: [
        TransportCarrierCollectionService
    ],
    exports: [
        RouterModule
    ]
})
export class LogisticsModule { }