import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { Router, ActivationEnd, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { SuiLocalizationService } from 'ng2-semantic-ui';
import { AppScopeService, UIService } from './core';

@Component({
	selector: 'app',
	templateUrl: "/template/app.component.cshtml",
	providers: [SuiLocalizationService]
})
export class AppComponent implements OnInit {
	private _bodyClassNames: Array<string> = [];

	constructor(
		private _renderer: Renderer2,
		private _router: Router,
		private _appScopeService: AppScopeService, // !!! - Je to tady aby byla služba inicializována
		localizationService: SuiLocalizationService,
		private _uiService: UIService) {

		this.setupLocalization(localizationService);
	}

	ngOnInit() {
		this._router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				// Zobrazím loader
				this._uiService.showLoader();

				// Resetuju classy pro body
				this._bodyClassNames = [];
			}

			if (event instanceof NavigationEnd ||
				event instanceof NavigationCancel) {
				// Skrývám loader
				this._uiService.hideLoader();
			}

			if (event instanceof NavigationEnd) {
                // Nastavím body class
                this._renderer.setAttribute(document.body, 'class', this._bodyClassNames.join(' '));
			}

			// Add body class based on current route
			// We are traversing routes from root
			if (event instanceof ActivationEnd) {
				let bodyClass = event.snapshot.data['bodyClass'];

				if (bodyClass) {
					this._bodyClassNames.push(bodyClass);
				}
			}
		});
	}

	private setupLocalization(localizationService: SuiLocalizationService) {
		localizationService.load('cs', {
			datepicker: {
				months: [
					'Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen',
					'Září', 'Říjen', 'Listopad', 'Prosinec'
				],
				monthsShort: [
					'Led', 'Úno', 'Bře', 'Dub', 'Kvě', 'Čer', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'
				],
				weekdays: [
					'Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'
				],
				weekdaysShort: [
					'Ned', 'Pon', 'Úte', 'Stř', 'Čtv', 'Pát', 'Sob'
				],
				weekdaysNarrow: [
					'Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'
				],
				//timesOfDay: [
				//	'dop', 'odp'
				//],
				//timesOfDayUppercase: [
				//	'dop', 'odp'
				//],
				//timesOfDayLowercase: [
				//	'dop', 'odp'
				//],
				formats: {
					time: 'h:mm',
					datetime: 'D.M.YYYY h:mm',
					date: 'D.M.YYYY',
					month: 'MMMM YYYY',
					year: 'YYYY'
				},
				firstDayOfWeek: 1 // First day of the week, [0..6], 0 is Sunday, 6 is Saturday
			},
			search: {
				placeholder: '', // Default placeholder for search input
				noResults: { // Message shown when there are no search results
					header: '',
					message: ''
				}
			},
			select: {
				noResultsMessage: '', // Shown when there are no search results
				single: { // Specific options for single-select
					placeholder: 'vyberte' // Default placeholder
				},
				multi: { // Specific options for multi-select
					placeholder: 'vyberte', // Default placeholder
					maxSelectedMessage: '' // Shown when max options selected (use #{max} to display value)
				}
			}
		});

		localizationService.setLanguage('cs');
	}
}