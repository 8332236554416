import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import {
	AuthenticatedGuard,
	AppLayoutComponent,
	NotFoundPageComponent,
    NotAuthorizedPageComponent,
    SignInPageComponent,
    LostPasswordComponent,
    DashboardPageComponent,
    PasswordChangeComponent,

    FirstLoggedComponent
} from './core';

import { SyncModule } from './sync';
import { LogModule } from './logs';
import { ContactsModule } from './contacts';
import { NotificationsModule } from './notifications';
import { LogisticsModule } from './logistics';

const appRoutes: Routes = [
	{
		path: '',
		redirectTo: 'app/dashboard',
		pathMatch: 'full'
	},
    {
		path: 'signin',
		component: SignInPageComponent,
		data: {
			bodyClass: 'signin-page'
		}
    },
    {
        path: 'lost-password',
        component: LostPasswordComponent,
        data: {
            bodyClass: 'signin-page'
        }
    },
    {
        path: 'first-logged',
        component: FirstLoggedComponent,
        data: {
            bodyClass: 'signin-page'
        }
    },
	{
		path: 'app',
		component: AppLayoutComponent,
		canActivateChild: [AuthenticatedGuard],
		children: [
			{
                path: 'not-authorized',
                component: NotAuthorizedPageComponent
            },
            {
                path: 'password-change',
                component: PasswordChangeComponent

            },
		    {
		        path: 'dashboard',
		        component: DashboardPageComponent,
		    },
			{
				path: 'commissions',
				loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\commissions\\index')['CommissionsModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
				data: {
					appScope: 'Commissions'
				}
			},
			{
				path: 'portal',
				children: [
					{
						path: 'commissions',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\portal\\commissions\\index')['PortalCommissionsModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Portal.{garantId}.Commission'
						}
					},
					{
						path: 'complaints',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\portal\\complaints\\index')['PortalComplaintsModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Portal.{garantId}.Complaint'
						}
					},
					{
						path: 'docs',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\portal\\docs\\index')['PortalDocsModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Portal.{garantId}.Docs'
						}
					},
					{
						path: 'instructions',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\portal\\instruction\\index')['PortalInstructionModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Portal.{garantId}.Instruction'
						}
					},
					{
						path: 'orders',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\portal\\orders\\index')['PortalOrdersModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Portal.{garantId}.Order'
						}
					},
					{
						path: 'support',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\portal\\support\\index')['PortalSupportModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Portal.{garantId}.Support'
						}
					}
				],
				data: {
					appScope: 'Portal'
				},
				canLoad: [AuthenticatedGuard]
            },
			{
				path: 'list',
				children: [
					{
						path: 'transport-carriers',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\transport-carriers\\index.routing')['TransportCarrierRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.Transport.Carrier'
						}
					},
					{
						path: 'transport-types',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\transport-types\\index.routing')['TransportTypeRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.Transport.Types'
						}
					},
					{
						path: 'commission-events',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\commission-events\\index.routing')['CommissionEventsRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.Commission.Event'
						}
					},
					{
						path: 'commission-placeofcomplaint',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\commission-placeofcomplaints\\index.routing')['CommissionPlaceOfComplaintRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.Commission.PlaceOfComplaint'
						}
					},
					{
						path: 'commission-kinds',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\commission-kinds\\index.routing')['CommissionKindRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.Commission.Kind'
						}
					},
					{
						path: 'commission-solvers',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\commission-solvers\\index.routing')['CommissionSolverRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.Commission.Solver'
						}
					},
					{
						path: 'commission-states',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\commission-states\\index.routing')['CommissionStateRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.Commission.State'
						}
					},
					{
						path: 'commission-types',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\commission-types\\index.routing')['CommissionTypeRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.Commission.Type'
						}
					},
					{
						path: 'device-category',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\device-category\\index.routing')['DeviceCategoryRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.Device.Category'
						}
					},
					{
						path: 'device-model',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\device-model\\index.routing')['DeviceModelRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.Device.Model'
						}
					},
					{
						path: 'device-policy',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\device-policy\\index.routing')['DevicePolicyRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.Device.Policy'
						}
					},
					{
						path: 'device-producer',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\device-producer\\index.routing')['DeviceProducerRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.Device.Producer'
						}
					},
					{
						path: 'device-type',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\device-type\\index.routing')['DeviceTypeRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.Device.Type'
						}
                    },
                    {
                        path: 'operationtypes',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\operation-type\\index.routing')['ListsOperationTypeRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Lists.OperationType'
                        }
                    },
                    {
                        path: 'fees',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\fees\\index.routing')['ListsFeesRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Lists.Fees'
                        }
                    },
                    {
                        path: 'requiredsolutions',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\required-solution\\index.routing')['ListsRequiredSolutionRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Lists.RequiredSolution'
                        }
                    },
                    {
                        path: 'finalsolutions',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\final-solution\\index.routing')['ListsFinalSolutionRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Lists.FinalSolution'
                        }
                    },
                    {
                        path: 'accessories',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\accessories\\index.routing')['ListsAccessoriesRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Lists.Accessories'
                        }
                    },
                    {
                        path: 'irisis',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\iris\\index.routing')['ListsIrisRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Lists.Iris'
                        }
                    },
                    {
                        path: 'pricelists',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\price-list\\index.routing')['ListsPriceListRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Lists.Pricelists'
                        }
					},
					{
						path: 'commission-authorization-types',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\lists\\commission-authorization-types\\index.routing')['CommissionAuthorizationTypeRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
						data: {
							appScope: 'Lists.CommissionAuthorization.Type'
						}
					}
				]
			},
            {
                path: 'settings',
                children: [
                    {
                        path: 'users',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\settings\\user\\index.routing')['SettingUserRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Settings.User'
                        }
                    },
                    {
                        path: 'branches',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\settings\\branch\\index.routing')['SettingBranchRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Settings.Branch'
                        }
                    },
                    {
                        path: 'general',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\settings\\general-settings\\index.routing')['SettingGeneralSettingsRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Settings.General'
                        }
                    },
                    {
                        path: 'roles',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\settings\\role\\index.routing')['SettingRoleRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Settings.Role'
                        }
                    },
                    {
                        path: 'modules',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\settings\\modules\\index.routing')['SettingModuleListRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Settings.Module'
                        }
                    },
                    {
                        path: 'module3',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\settings\\modules\\module3\\index.routing')['SettingModulesModule3RoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Settings.Module'
                        }
                    },
                    {
                        path: 'module1',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\settings\\modules\\module1\\index.routing')['SettingModulesModule1RoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Settings.Module'
                        }
                    },
                    {
                        path: 'servistrendconnector',
                        loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\settings\\modules\\servistrendconnector\\index.routing')['SettingModulesServiceTrendConnectorRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
                        data: {
                            appScope: 'Settings.Module'
                        }
					}
                ]
            },

		    {
		        path: 'sync',
                loadChildren: () => SyncModule,
		        data: {
                    appScope: 'System'
		        }
		    },
		    {
		        path: 'logs',
		        loadChildren: () => LogModule,
		        data: {
                    appScope: 'System'
		        }
            },
		    {
		        path: 'contacts',
                loadChildren: () => ContactsModule,
		        data: {
                    appScope: 'Contact'
		        }
            },
		    {
                path: 'notifications',
                loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\notifications\\index.routing')['NotificationsRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
		        data: {
		            appScope: 'Notifications'
		        }
			},
			{
				path: 'svt',
				loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\svt\\index.routing')['SvtRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
				data: {
					appScope: 'Svt'
				}
			},
		    {
		        path: 'logistics',
		        loadChildren: () => LogisticsModule,
		        data: {
		            appScope: 'Logistics'
		        }
			},
			{
				path: 'modules',
				children: [
					{
						path: 'sumup',
						loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\modules\\sumup\\index.routing')['SumUpRoutingModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) }
					}
				]
			}
		]
	},
	{ path: '**', component: NotFoundPageComponent }
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(
	appRoutes,
	{
		useHash: true,
		enableTracing: false
	});