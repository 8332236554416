import { Component } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';

@Component({
    templateUrl: "/template/core/modals/confirm.cshtml"
})
export class ConfirmModalComponent {
	constructor(
		public modal: SuiModal<IConfirmModalContext, void>) {
	}
}

export class ConfirmModal extends ComponentModalConfig<IConfirmModalContext, void, void> {
    constructor(title: string, message: string) {
		super(ConfirmModalComponent, { title, message });

        this.isClosable = false;
        this.transitionDuration = 200;
        this.size = ModalSize.Small;
    }
}

/**
 * Rozhraní pro kontext confirm modalu
 */
interface IConfirmModalContext {
	title: string;
	message: string;
}