import { Injectable, Inject } from '@angular/core';
import * as moment from 'moment';

function isObject(item) {
	return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Služba poskytující různé pomocné funkce.
 * 
 * @export
 * @class HelperService
 */
@Injectable()
export class HelperService {

	// 2017-07-31T22:00:00+00:00
	private regexIso8601: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d*)?(Z|[\-+]{1}\d{2}:\d{2})$/;

	/**
	 * To url přdá query string parametry z daného objektu
	 * @param url
	 * @param query
	 */
	public appendQueryString(url: string, query: any): string {
		let params: Array<string> = [];

		for (let key of Object.keys(query)) {
			params.push(key + '=' + encodeURIComponent(query[key]));
		}

		if (url.indexOf('?') > -1) {
			url += '&';
		}
		else {
			url += '?';
		}

		url += params.join('&');

		return url;
	}

	/**
	 * V JSON objektu konvertuje všechny Date objekty na ISO stringy
	 * @param input
	 */
	public convertDatesToDateStrings(input: any): void {
		// Ignore things that aren't objects.
		if (typeof input !== 'object') return;

		for (var key in input) {
			if (!input.hasOwnProperty(key)) continue;

			var value = input[key];

			// Check for Date property
			if (value instanceof Date) {
                input[key] = moment(value).format();
			} else if (typeof value === 'object') {
				// Recurse into object
				this.convertDatesToDateStrings(value);
			}
		}
	}

	/**
	 * V JSON objektu konvertuje všechny stringy reprezentující datum do objektu Date
	 * @param input
	 */
    public convertDateStringsToDates(input: any): void {

		// Ignore things that aren't objects.
		if (typeof input !== 'object') return;

		for (var key in input) {
			if (!input.hasOwnProperty(key)) continue;

			var value = input[key];

            // Check for string properties which look like dates.
            if (typeof value === 'string' && this.regexIso8601.test(value)) {
                input[key] = moment.parseZone(value).toDate();
                //console.log(value, input[key]);
			} else if (typeof value === 'object') {
				// Recurse into object
				this.convertDateStringsToDates(value);
			}
		}
	}

	/**
	 * Sestaví absolutní url
	 * 
	 * @param url
	 */
	public createAbsoluteUrl(url: string): string {
		if (!url) {
			return url;
		}

		return window.location.origin + (url[0] == '/' ? '' : '/') + url;
	}

	/**
	 * Vrátí deep kopii objektu.
	 *
	 * @param x
	 */
	public deepClone(x: any): any {
		return JSON.parse(JSON.stringify(x), this._deepCloneReviver)
	}

	/**
	 * Vrací info jestli jesou dva obejkty shodné.
	 *
	 * @param x
	 * @param y
	 */
	public deepEquals(x, y) {
		if (x === y) {
			return true; // if both x and y are null or undefined and exactly the same
		} else if (!(x instanceof Object) || !(y instanceof Object)) {
			// if they are not strictly equal, they both need to be Objects
			return false;
		} else if (x.constructor !== y.constructor) {
			// they must have the exact same prototype chain, the closest we can do is
			// test their constructor.
			return false;
		} else if (x instanceof Date && y instanceof Date) {
			return x.toISOString() == y.toISOString();
		} else {
			for (const p in x) {
				if (!x.hasOwnProperty(p)) continue; // other properties were tested using x.constructor === y.constructor
				if (!y.hasOwnProperty(p)) return false; // allows to compare x[ p ] and y[ p ] when set to undefined
				if (x[p] === y[p]) continue; // if they have the same strict value or identity then they are equal
				if (typeof (x[p]) !== 'object') return false; // Numbers, Strings, Functions, Booleans must be strictly equal
				if (!this.deepEquals(x[p], y[p])) return false;
			}

			for (const p in y) {
				if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
					return false;
				}
			}

			return true;
		}
	}

	/**
	 * Merge objektů do jednoho - totéž co angular.extend angular.js
	 * @param to
	 * @param from
	 */
	public extend<T>(to: T, from: T): T {
		if (isObject(to) && isObject(from)) {
			for (const key in from) {
				if (isObject(from[key])) {
					if (!to[key]) Object.assign(to, { [key]: {} });
					this.extend(to[key], from[key]);
				} else {
					Object.assign(to, { [key]: from[key] });
				}
			}
		}

		return to;
	}

	/**
	 * Přidá tolik znaků na začátek hodnoty, aby celková délka byla jako definovaná
	 * @param value
	 * @param char
	 * @param size
	 */
	public padStart(value: string | number, char: string, size: number): string {
		let retval = value.toString();

		while (retval.length < size) {
			retval = char + retval;
		}

		return retval;
	}

	/**
	 * Zaokrouhluje na definovaný počet desetinných míst
	 * @param value
	 * @param decimals
	 */
	public round(value: number, decimals: number): number {
		let exp = Math.pow(10, decimals);
		return Math.round(value * exp) / exp;
	}

	/**
	 * Funkce zajišťuje správné klonování datumů.
	 *
	 * @param key
	 * @param value
	 */
	private _deepCloneReviver(key: any, value: any): any {
		if (typeof value == 'string' && value.length > 0 && /^\d{4}\-\d{2}\-\d{2}T[0-9\:\.]*Z$/.test(value)) {
			return new Date(value);
		}

		return value;
	}
}