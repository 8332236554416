import { Injectable, Inject, Injector } from '@angular/core';
import { Http, Response, RequestOptionsArgs, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { HelperService } from '../services/helper';
import { map, catchError } from 'rxjs/operators';

/**
 * Služba pro lowlevel volání API.
 */
@Injectable()
export class ApiService {
	private static readonly TOKEN_COOKIE_NAME = 'tkn';

	private _token: string = null;
	private _apiUrl: string = null;

	constructor(
		private _http: Http,
		private _cookieService: CookieService,
		private _helperService: HelperService,
		private _injector: Injector) {

        this._apiUrl = [
            window.location.protocol,
			'//',
            window.location.hostname,
			':',
            window.location.port,
			'/webapi'
		].join('');
	}

	/**
	 * Vrátí nebo nastaví JWT token
	 * @readonly
	 * @type {string}
	 */
	public get token(): string {
		// Pokud je token null, pokusíme se načíst z cookie
		// Pokud není v cookie, nastavíme prázdný string
		if (this._token == null) {
			this._token = this._cookieService.get(ApiService.TOKEN_COOKIE_NAME) || '';
		}

        return this._token;
    }
	public set token(value: string) {
		// Pokud neni zadano, pak mazu cookie; jinak ukládám do cookie
		if (!value || value.length === 0) {
			this._cookieService.remove(ApiService.TOKEN_COOKIE_NAME);
			this._token = '';

			return;
		}

		this._cookieService.put(ApiService.TOKEN_COOKIE_NAME, value);
        this._token = value;
	}

	/**
	 * DELETE
	 */
	public delete(path: string, options?: RequestOptionsArgs): Promise<any> {
		return this.as(
			this._http
				.delete(this.createUrl(path), this.setupOptions(path, options))
				.pipe(catchError(x => this.catchError(x)))
		);
	}

	/**
	 * GET
	 */
	public get<T>(path: string, options?: RequestOptionsArgs): Promise<T> {
		return this.as<T>(
			this._http
				.get(this.createUrl(path), this.setupOptions(path, options))
				.pipe(catchError(x => this.catchError(x)))
		);
	}

	/**
	 * GET - returns observable
	 */
	public getObservable<T>(path: string, options?: RequestOptionsArgs): Observable<T> {
		return this.asObservable<T>(
			this._http
				.get(this.createUrl(path), this.setupOptions(path, options))
				.pipe(catchError(x => this.catchError(x)))
		);
	}

	/**
	 * POST
	 */
	public post<T>(path: string, body: any, options?: RequestOptionsArgs): Promise<T> {
		this._helperService.convertDatesToDateStrings(body);

		return this.as<T>(
			this._http
				.post(this.createUrl(path), body, this.setupOptions(path, options))
				.pipe(catchError(x => this.catchError(x)))
		);
	}

	/**
	 * POST
	 */
	public postRaw(path: string, body: any, options?: RequestOptionsArgs): Promise<Response> {
		this._helperService.convertDatesToDateStrings(body);

		return this._http
			.post(this.createUrl(path), body, this.setupOptions(path, options))
			.toPromise();
	}

	/**
	 * PUT
	 */
	public put<T>(path: string, body: any, options?: RequestOptionsArgs): Promise<T> {
		this._helperService.convertDatesToDateStrings(body);

		return this.as<T>(
			this._http
				.put(this.createUrl(path), body, this.setupOptions(path, options))
				.pipe(catchError(x => this.catchError(x)))
		);
	}

	/**
	 * Metoda pro zpracování vyjímek při API voláních.
	 */
	private catchError(res: Response): Observable<any> {

		// Pro 401 - Unauthorized děláme redirekt na login
		if (res.status == 401) {
			this.token = null;

			let router = this._injector.get(Router);

			router.navigate(['/signin']);

			return throwError('Not authorized');
		}

		let error: ErrorResponse = {
			status: res.status,
			errors: [],
			isUserFriendly: false
		};

		let body = res.json();

		error.isUserFriendly = body.isUserFriendly;

		if ('errors' in body && Array.isArray(body.errors)) {
			error.errors = body.errors;
		}
		else {
			error.errors.push({ key: null, message: res.statusText });
		}

		return throwError(error);
	}

	/**
	 * Vytvoří výslednou url pro volání.
	 */
	createUrl(path: string): string {
		if (!path) {
			throw new Error('Unable to call API without path specified');
		}

		if (path[0] != '/') {
			path = '/' + path;
		}

		return this._apiUrl + path;
	}

	/**
	 * Nastaví potřebné parametry pro AJAX request.
	 */
	private setupOptions(path: string, options?: RequestOptionsArgs): RequestOptionsArgs {
		if (!options) {
			options = {};
		}

		if (!options.headers) {
			options.headers = new Headers();
		}

		let headers = options.headers;

		// Nastavíme JWT token do hlaviček
		let token = this.token;

		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}

		if (headers.get('Content-Type') == 'multipart/form-data') {
			headers.delete('Content-Type');
		}
		else {
			headers.set('Content-Type', 'application/json; charset=utf-8');
		}

		headers.set('X-Requested-With', 'XMLHttpRequest');

		return options;
	}

	/**
	 * Provede konverzi Response na generický typ T
	 */
	private as<T>(observable: Observable<Response>): Promise<T> {
		return observable.pipe(map(res => {
			if (res.text().length == 0) return null;
			
			let retval = res.json();
			this._helperService.convertDateStringsToDates(retval);

			return retval as T;
		}))
		.toPromise();
	}

	/**
	 * Provede konverzi Response na generický typ T
	 */
	private asObservable<T>(observable: Observable<Response>): Observable<T> {
		return observable.pipe(map(res => {
			if (res.text().length == 0) return null;

			let retval = res.json();
			this._helperService.convertDateStringsToDates(retval);

			return retval as T;
		}));
	}
}

export class ErrorResponse {
	status: number = null;
	errors: Array<ErrorMessage> = [];
	isUserFriendly: boolean = false;
}

export class ErrorMessage {
	key: string = null;
	message: string = null;
}