import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CommonPermission, CoreModule, CanDeactivateGuard } from '../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from 'ng2-semantic-ui';

// Services
import { SyncService } from './services/sync';

// Pages
import { SyncDashboardPageComponent } from './pages/dashboard';

// Routes
const routes: Routes = [
    {
        path: 'dashboard',
        data: {
            
        },
        component: SyncDashboardPageComponent
    }
]

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        RouterModule.forChild(routes),
        FormsModule,
        SuiModule
    ],
    declarations: [
        SyncDashboardPageComponent
    ],
    exports: [
        RouterModule
    ],
    providers: [
        SyncService
    ]
})
export class SyncModule { }