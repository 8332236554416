import { Component, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { ListsService, IListSettingsHandler } from '../services/lists';
import { UIService } from '../services/ui';
import { ValuesService } from '../services/values';
import { HelperService } from '../services/helper';
import { ColumnsSetupModal } from '../modals/columns-setup';
import {
	ListFilterOption,
	ListFilter,
	ListFilterEntry,
	SelectOption,
	ListFilterOptionType,
	ColumnSettings,
	ListSettingsItem,
	ListFilterEntryCompare,

	ListSettings
} from '../models';
import { DatepickerComponent } from '../components/datepicker';
import { ModalService } from '../services/modal';

@Component({
	templateUrl: "/template/core/modals/list-filter.cshtml"
})
export class ListFilterModalComponent implements OnInit {
	// Handler pro nastavení tohohle gridu
	private _settingsHandler: IListSettingsHandler;

	/**
	 * Možnosti pro filtr
	 */
	public filterOptions: Array<ListFilterOption> = [];

	/**
	 * Pole hodnot možností filtru použité pro select box
	 */
	public filterOptionsValues: Array<SelectOption> = [];

	/**
	 * Aktuální filtr
	 */
	public filter: ListFilter = new ListFilter();

	/**
	 * Aktuální nastavení sloupců
	 */
	public columns: Array<ColumnSettings> = [];

	/**
	 * Model pro název nastavení ve formuláři pro uložení
	 */
	public settingsName: string = '';

	/**
	 * Jestli se načítají data
	 */
	public loading: boolean = false;

	/**
	 * Seznam uložených nastavení
	 */
	public settings: Array<ListSettingsItem> = [];

	constructor(
		public modal: SuiModal<IListFilterModalContext, void>,
		private _listsService: ListsService,
		private _modalService: ModalService,
		private _uiService: UIService,
		private _helperService: HelperService,
		private _valuesService: ValuesService) {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		// Načteme možnosti filtru
		this._listsService.getFilterOptions(this.modal.context.listType)
			.then(x => {
				this.filterOptions = x;

				// Vytvoříme hodnoty pro select
				this.filterOptionsValues = this.filterOptions.map(x => new SelectOption(x.code, x.title));

				// Načteme handler nastavení
				this._listsService.getSettingsHandler(this.modal.context.listType)
					.then(x => {
						this._settingsHandler = x;

						this.filter = this._helperService.deepClone(x.filter);
						this.columns = this._helperService.deepClone(x.columns);
					});
			});

		// Načteme uložené nastavení
		this._loadSettingsList();
	}

	/**
	 * Přidá novou položku do filtru
	 */
	public addEntry(): void {
		let option: ListFilterOption = this.filterOptions[0];

		// Get compare values for
		this._valuesService.getValues(`ListFilterEntryCompare?forType=${option.type}`)
			.then(values => {
				this.filter.entries.push(new ListFilterEntry(this.filterOptions[0].code, values[0].value));
			});
	}

	/**
	 * Smaže nastavení
	 * @param settings
	 */
	public deleteSettings(settings: ListSettingsItem): void {
		this.loading = true;

		this._listsService.deleteSettings(settings.id)
			.then(x => {
				this._uiService.showSuccess(`Nastavení ${settings.name} bylo smazáno.`, 'Smazáno');
				this.loading = false;

				this._loadSettingsList();
			})
			.catch(x => {
				this._uiService.showError('Nastavení se nepodařilo smazat.');
				this.loading = false;
			});
	}

	/**
	 * Vrací info jestli je první input zobrazen
	 * @param entry
	 */
	public firstValueIsVisible(entry: ListFilterEntry): boolean {
		return entry.compare != ListFilterEntryCompare.IsFill;
	}

	/**
	 * Vrací možnost filtru pro dodaný kód
	 * @param code
	 */
	public getOption(code: string): ListFilterOption {
		return this.filterOptions.find(x => {
			return x.code == code;
		});
	}

	/**
	 * Handluje změnu typu porovnání pro položku filtru
	 * @param entry
	 */
	public onCompareChange(entry: ListFilterEntry, value: ListFilterEntryCompare): void {
		entry.compare = value;

		this._updateEntryText(entry, null);
	}

	/**
	 * Při změně možnosti u položky filtru nastaví potřebné
	 * @param entry
	 * @param value
	 */
	public onEntryOptionChange(entry: ListFilterEntry, value: string): void {
		let option: ListFilterOption = this.getOption(value);

		// Get compare values for
		this._valuesService.getValues(`ListFilterEntryCompare?forType=${option.type}`)
			.then(values => {
				// Nastavím option podle hodnoty
				entry.optionCode = value;
				entry.compare = values[0].value;
				entry.value1 = null;
				entry.value2 = null;
			});
	}

	/**
	 * Obsluhuje změnu hodnoty jestli negovat
	 * @param entry
	 * @param value
	 */
	public onIsNegationChange(entry: ListFilterEntry, value: boolean): void {
		entry.isNegation = value;

		this._updateEntryText(entry, value);
	}

	/**
	 * Obsluhuje změnu první hodnoty v položce filtru
	 * @param entry
	 * @param control1
	 * @param control2
	 * @param value
	 */
	public onValue1Change(entry: ListFilterEntry, control1: any, control2: any, value: any): void {
		let option = this.getOption(entry.optionCode);

		if (option.type == ListFilterOptionType.Month && control2) {
			let datepicker1 = <DatepickerComponent>control1;
			let datepicker2 = <DatepickerComponent>control2;

			datepicker2.setMinDate(datepicker1.value);
		}

		if (value instanceof SelectOption) {
			entry.value1 = (<SelectOption>value).value;
		} else {
			entry.value1 = value;
		}

		this._updateEntryText(entry, value);
	}

	/**
	 * Obsluhuje změnu druhé hodnoty v položce filtru
	 * @param entry
	 * @param control1
	 * @param control2
	 * @param value
	 */
	public onValue2Change(entry: ListFilterEntry, control1: any, control2: any, value: any): void {
		let option = this.getOption(entry.optionCode);

		if (option.type == ListFilterOptionType.Month) {
			let datepicker1 = <DatepickerComponent>control1;
			let datepicker2 = <DatepickerComponent>control2;

			datepicker1.setMaxDate(datepicker2.value);
		}

		entry.value2 = value;

		this._updateEntryText(entry, value);
	}

	/**
	 * Odebere položku z filtru
	 * @param entry
	 */
	public removeEntry(entry: ListFilterEntry): void {
		let idx = this.filter.entries.indexOf(entry);

		this.filter.entries.splice(idx, 1);
	}

	/**
	 * Resetuje nastavené filtry
	 */
	public resetFilters(): void {
		this.filter = new ListFilter();
	}

	/**
	 * Uloží nastavení pod zadaným názvem. 
	 */
	public saveSettings(): void {
		this.loading = true;

		// Vytvářím objekt který bude uložen
		let settings: ListSettings = {
			columns: this._helperService.deepClone(this.columns),
			filter: this._helperService.deepClone(this.filter),
			page: null,
			sorting: null
		};

		settings.filter.searchFor = '';

		this._listsService.saveSettings(this.modal.context.listType, this.settingsName, settings)
			.then(x => {
				this.settingsName = '';

				this._uiService.showSuccess('Nastavení bylo v pořádku uloženo', 'Uloženo');
				this.loading = false;

				this._loadSettingsList();
			})
			.catch(x => {
				this._uiService.showError('Nastavení se nepodařilo uložit', 'Neuloženo');
				this.loading = false;
			});
	}

	/**
	 * Vrací info jestli je druhý input zobrazen
	 * @param entry
	 */
	public secondValueIsVisible(entry: ListFilterEntry): boolean {
		return entry.compare == ListFilterEntryCompare.Between;
	}

	/**
	 * Nastaví nakonfigurovaný filtr a zavře modal
	 */
	public setFilter(): void {
		this._settingsHandler.setColumnsAndFilter(this.columns, this.filter);

		this.modal.approve(undefined);
	}

	/**
	 * Nastaví nastavení zvolené ze seznamu uložených nastavení
	 * @param settings
	 */
	public setSettings(settings: ListSettingsItem): void {
		this.filter = this._helperService.deepClone(settings.settings.filter);
		this.columns = this._helperService.deepClone(settings.settings.columns);
	}

	/**
	 * Otevře modál s nastavením sloupců.
	 */
	public setupColumns(): void {
		this._listsService.getColumnDefinitions(this.modal.context.listType)
			.then(x => {
				this._modalService
					.openModal(new ColumnsSetupModal(this.columns, x))
					.onApprove((x: Array<ColumnSettings>) => {
						this.columns = x;
					});
			});
	}

	/**
	 * načte seznam uložených nastavení 
	 */
	private _loadSettingsList(): void {
		this.loading = true;

		this._listsService.loadSettingsList(this.modal.context.listType)
			.then(x => {
				this.settings = x;
				this.loading = false;
			})
			.catch(x => {
				this._uiService.showError('Nepodařilo se načíst seznam uložených nastavení.');
				this.loading = false;
			});
	}

	/**
	 * Vytvoří textovou reprezentaci vybrané položky ve filtru.
	 * Zobrazuje se pak jako seznam vybraných hodnot pro filtrování.
	 * TODO: Přehodti jinam - tady není nejlepší místo
	 * @param entry
	 * @param value
	 */
	private _updateEntryText(entry: ListFilterEntry, value: any): void {
		if (!entry.value1 && entry.value1 != 0) {
			entry.text = '';
			return;
		}

		let option = this.getOption(entry.optionCode);
		let parts: Array<string> = [option.title, ': '];

		switch (option.type) {
			case ListFilterOptionType.String:
				switch (entry.compare) {
					case ListFilterEntryCompare.Equal:
						parts.push(entry.isNegation ? 'není ' : '');
						parts.push(entry.value1);
						break;

					case ListFilterEntryCompare.IsFill:
						parts.push(entry.isNegation ? 'není zadáno' : 'je zadáno');
						break;

					case ListFilterEntryCompare.Like:
						parts.push(entry.isNegation ? 'neobsahuje ' : 'obsahuje ');
						parts.push(entry.value1);
						break;
				}
				break;

			case ListFilterOptionType.Date:
				let date1 = <Date>entry.value1;
				let date2 = <Date>entry.value2;

				switch (entry.compare) {
					case ListFilterEntryCompare.Between:
						parts.push(entry.isNegation ? 'není ' : '');
						parts.push(this._formatDate(date1));
						parts.push(' - ');
						if (date2) {
							parts.push(this._formatDate(date2));
						}
						break;

					case ListFilterEntryCompare.Equal:
						parts.push(entry.isNegation ? 'není ' : '');
						parts.push(this._formatDate(date1));
						break;

					case ListFilterEntryCompare.IsFill:
						parts.push(entry.isNegation ? 'není zadáno' : 'je zadáno');
						break;

					case ListFilterEntryCompare.IsGreater:
						parts.push(entry.isNegation ? '<= ' : '> ');
						parts.push(this._formatDate(date1));
						break;

					case ListFilterEntryCompare.IsLower:
						parts.push(entry.isNegation ? '>= ' : '< ');
						parts.push(this._formatDate(date1));
						break;
				}
				break;

			case ListFilterOptionType.Number:
				switch (entry.compare) {
					case ListFilterEntryCompare.Between:
						parts.push(entry.isNegation ? 'není ' : '');
						parts.push(entry.value1);
						parts.push(' - ');
						parts.push(entry.value2);
						break;

					case ListFilterEntryCompare.Equal:
						parts.push(entry.isNegation ? 'není ' : '');
						parts.push(entry.value1);
						break;

					case ListFilterEntryCompare.IsFill:
						parts.push(entry.isNegation ? 'není zadáno' : 'je zadáno');
						break;

					case ListFilterEntryCompare.IsGreater:
						parts.push(entry.isNegation ? '<= ' : '> ');
						parts.push(entry.value1);
						break;

					case ListFilterEntryCompare.IsLower:
						parts.push(entry.isNegation ? '>= ' : '< ');
						parts.push(entry.value1);
						break;
				}
				break;

			case ListFilterOptionType.Enum:
				switch (entry.compare) {
					case ListFilterEntryCompare.Equal:
						parts.push(entry.isNegation ? 'není ' : '');
						parts.push((<SelectOption>value).text);
						break;

					case ListFilterEntryCompare.IsFill:
						parts.push(entry.isNegation ? 'není zadáno' : 'je zadáno');
						break;
				}

				break;

			case ListFilterOptionType.Month:
				let monthDate1 = <Date>entry.value1;
				let monthDate2 = <Date>entry.value2;

				switch (entry.compare) {
					case ListFilterEntryCompare.Between:
						parts.push(entry.isNegation ? 'není ' : '');
						parts.push(this._formatMonth(monthDate1));
						parts.push(' - ');
						if (monthDate2) {
							parts.push(this._formatMonth(monthDate2));
						}
						break;

					case ListFilterEntryCompare.Equal:
						parts.push(entry.isNegation ? 'není ' : '');
						parts.push(this._formatMonth(monthDate1));
						break;

					case ListFilterEntryCompare.IsFill:
						parts.push(entry.isNegation ? 'není zadáno' : 'je zadáno');
						break;

					case ListFilterEntryCompare.IsGreater:
						parts.push(entry.isNegation ? '<= ' : '> ');
						parts.push(this._formatMonth(monthDate1));
						break;

					case ListFilterEntryCompare.IsLower:
						parts.push(entry.isNegation ? '>= ' : '< ');
						parts.push(this._formatMonth(monthDate1));
						break;
				}
				break;

			default:
				entry.text = 'Entry option type not supported!';
		}

		entry.text = parts.join('');
	}

	/**
	 * Zformátuje dodané datum jako den - 01.2018
	 * @param date
	 */
	private _formatDate(date: Date): string {
		return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
	}

	/**
	 * Zformátuje dodané datum jako měsíc - 01.2018
	 * @param date
	 */
	private _formatMonth(date: Date): string {
		return `${date.getMonth() + 1}.${date.getFullYear()}`;
	}
}

export class ListFilterModal extends ComponentModalConfig<IListFilterModalContext, void, void> {
	constructor(listType: string) {
		super(ListFilterModalComponent, { listType });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.isFullScreen = true;
	}
}

/**
 * Rozhraní pro kontext confirm modalu
 */
interface IListFilterModalContext {
	listType: string;
}