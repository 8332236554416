import { NavigationExtras } from "@angular/router";

export enum ScopePermissionType {
	None = 0,
	Read = 1 << 0,
	Create = 1 << 1,
	Update = 1 << 2,
	Delete = 1 << 3,

	All = Read | Create | Update | Delete
}

export enum CommonPermission {
	None = 0,
	ManageSettings = 1 << 0
}

export class CommissionSpecialPermission {
    isRolePossibleAssign: boolean = false;
    isRoleAssignedVisible: boolean = false;
    isRoleAssigneToOther: boolean = false;
    isRoleIndividualDiscount: boolean = false;
    isRoleAddPaymentCard: boolean = false;
    isRoleAddPaymentBankAccount: boolean = false;
    isRoleStornoPayment: boolean = false;
	isRoleClosing: boolean = false;
    isRoleClosedToEneded: boolean = false;
	isRoleChangeBranchOrTechnician: boolean = false;
	isEditInAllPhases: boolean = false;
}

export class PortalSpecialPermission {
    isAgent: boolean = false;
    isRoleCommissionOwnBranch: boolean = false;
	isRoleOrderOwnBranch: boolean = false;
}

export enum CommissionParticipation { None = 0, Percentage = 1, PriceWithVat = 2 }
export enum IrisType { Condition, Symptom, Section, Fault, Repair }
export enum CommissionPhase { Concept = 1, Active = 2, Ended = 4, Closed = 8 }
export enum CommissionPaymentType { NotSpecified = 0, Deposit = 1, Participation = 2, Commission = 3 }
export enum DiscountType { None = 0, Percentage = 1, PriceWithVat = 2 }
export enum PaymentMethodType { Cash = 0, PaymentCard = 1, BankTransfer = 2, CashOnDelivery = 3 }
export enum CommissionPriceProposalState { Waiting = 0, Approved = 1, Rejected = 2 }
export enum CommissionAttachmentType { Other = 0, SalesReceipt = 1, Label = 2 }
export enum CommissionPartState { Ordered = 0, Blocked = 1, Used = 2, Storno = 3, NotSet = 4, Unavailable = 5 }
export enum CommissionPaymentState { NotPaid = 0, NotSaved = 1, NotPaired = 2, Paired = 3, Removed = 4, Temporary = 5 }
export enum CommissionAuthorizationState { Waiting = 0, Approved = 1, Rejected = 2 }
export enum ListFilterEntryCompare { Like = 0, Equal = 1, IsFill = 2, IsLower = 3, IsGreater = 4, Between = 5 }
export enum ListFilterOperator { And = 0, Or = 1 }

export class Value {
	constructor(
		public value: any = null,
		public text: string = null
	) {
	}
}

export class ScopePermission {
	permissionType: ScopePermissionType = ScopePermissionType.None;
	scopePath: string = null;
}

export class CurrentUserInfo {
	id: number;
	fullName: string;
	needChangePassword: boolean = false;
	commonPermissions: CommonPermission = CommonPermission.None;
    scopePermissions: Array<ScopePermission> = [];
    commissionSpecialPermission: CommissionSpecialPermission;
    portalSpecialPermission: PortalSpecialPermission;
}

export class FileInfo {
	fileName: string = null;
	extension: string = null;
	filePath: string = null;
	thumbnailUrl: string = null;
	url: string = null;
}

export enum ProjectEnumType {
	PortalOrderPartState = 'PortalOrderPartState',
    PortalCommissionState = 'PortalCommissionState',
    TransportCarrierCollectionReclamationType = 'TransportCarrierCollectionReclamationType'
}

export class ProjectEnumValue {
	id: number = null;
	isDefault: boolean = false;
	value: string = null;
	text: string = null;
	data: any = null;
}

export class SelectOption {
	constructor(
		public value: string,
		public text: string
	) {
	}
}

/**
 * Definice sloupce v seznamu 
 */
export class ColumnDefinition {
	// Field pro který je definice
	public field: string;

	// Title zobrazený v hlavičce
	public title: string;

	// Jestli musí být vždy viditelný
	public isMandatory: boolean;

	// Jestli je sloupec fixovaný
	public isFixed: boolean;

	// Šířka sloupce
	public width: number | null;

	// Název formatteru pro vykreslení sloupce
	public formatter: string | null;

	/// Jestli má být sloupec viditelný v responsivu
	public isVisibleInResponsive: boolean;
}

/**
 * List struktura 
 */
export class List<T> {
	public items: Array<T> = [];

	/**
	 * Přidá položku do seznamu, pokud tam neexistuje.
	 * @param item
	 */
	public add(item: T): void {
		if (this.contains(item)) {
			return;
		}

		this.items.push(item);
	}

	/**
	 * Vrací informaci jestli daná položka existuje v seznamu.
	 * @param item
	 */
	public contains(item: T): boolean {
		return this.items.indexOf(item) > -1;
	}

	/**
	 * Odebere položku ze seznamu, pokud v něm existuje.
	 * @param item
	 */
	public remove(item: T) {
		let index = this.items.indexOf(item);

		if (index > -1) {
			this.items.splice(index, 1);
		}
	}

	/**
	 * Vloží položku pokud neexistuje v seznamu, jinak odebere ze seznamu.
	 * @param item
	 */
	public toggle(item: T) {
		let index = this.items.indexOf(item);

		if (index > -1) {
			this.items.splice(index, 1);
		} else {
			this.items.push(item);
		}
	}
}

/**
 * Dostupné velikosti stránek
 */
export const PAGE_SIZES: Array<number> = [10, 25, 50, 75, 100, 125, 150];

/**
 * Definuje řazení - podle čeho a ASC/DESC
 */
export class Sorting {
	public sortBy: string = null;
	public sortDesc: boolean = false;
}

/**
 * Definice stránky, kterou vrátit
 */
export class PageInfo {
	public page: number = 1;
	public itemsPerPage: number = 100;
}

/**
 * Stránka objektů
 */
export class PageModel<T> {
	public items: Array<T> = [];
	public totalCount: number = 0;
	public page: PageInfo = null;
}

//
// List filters
//

/**
 * Definice jedné konkrétní možnosti ve filtru.
 */
export class ListFilterOption {
	/**
	 * Kód použitý jako identifikátor
	 */
	public code: string = null;

	/**
	 * Titulek zobrazený ve filtru
	 */
	public title: string = null;

	/**
	 * Specifikuje type položky filtru
	 */
	public type: ListFilterOptionType = ListFilterOptionType.NotSpecified;

	/**
	 * Datový zdroj pro select box - pokud je type Enum
	 */
	public dataSource: string | Array<SelectOption> = null;
}

/**
 * Reprezentuje konkrétní filtr - uložený nebo neuložený
 */
export class ListFilter {
	constructor() {
	}

	/**
	 * Položky ve filtru
	 */
	public entries: Array<ListFilterEntry> = [];

	/**
	 * Výraz zadaný do pole vyhledávání.
	 */ 
	public searchFor: string = null;

    /** 
     * Vyfiltrovane sloupce
     */
    public columns: Array<ColumnSettings> = [];

     /** 
     * Vybrane radky
     */
    public checkedIds: Array<number> = [];

	/**
	 * Operator mezi entries
	 */
	public operator: ListFilterOperator = ListFilterOperator.And;

	/**
	 * Vytvoří instanci filtru pro danou položku.
	 * @param entry
	 */
	public static forEntry(entry: ListFilterEntry): ListFilter {
		let retval = new ListFilter();
		retval.entries.push(entry);

		return retval;
	}
}

/**
 * Reprezentuje jednu položku v konkrétním filtru.
 */
export class ListFilterEntry {
	constructor(optionCode: string, compare: ListFilterEntryCompare) {
		this.optionCode = optionCode;
		this.compare = compare;
	}

	/**
	 * Kód možnosti, podle které se bude filtrovat
	 */
	public optionCode: string;

	/**
	 * Typ porovnání zvolený pro položku filtru.
	 **/
	public compare: ListFilterEntryCompare;

	/**
	 * Hodnoty - může být jen jedna, např. text, nebo dvě - datum od-do
	 * Kolik a jaké hodnoty to budou závisí na ListFilterOptionType
	 */
	public value1: any = null;
	public value2: any = null;

	/**
	 * Textová reprezentace položky pro zobrazení.
	 */
	public text: string = null;

	/**
	 * Info jestli má být podmínka negována 
	 */
	public isNegation: boolean;
}

export enum ListFilterOptionType {
	NotSpecified = 0,
	String = 1,
	Date = 2,
	Number = 3,
	Enum = 4,
	Month = 5
}

/**
 * Nastavení konkrétního sloupce v seznamu. 
 */
export class ColumnSettings {
	// Field pro který sloupec je
	public field: string;

	// Jestli je sloupec schovaný
	public isHidden: boolean;
}

/**
 * Reprezentuje nastavení seznamu (řazení, filtr, ...)
 */
export class ListSettings {
	// Nastavení řazení
	public sorting: Sorting = new Sorting();

	// Filtr
	public filter: ListFilter = new ListFilter();

	// Stránkování
    public page: PageInfo = new PageInfo();

    // Nastavení sloupců
    public columns: Array<ColumnSettings> = [];
}

/**
 * nastavení seznamu které se ukládá do DB 
 */
export class ListSettings2 {
	// Filtr
	public filter: ListFilter = new ListFilter();

	// Nastavení sloupců
	public columns: Array<ColumnSettings> = [];
}

/**
 * Položka uloženého nastavení 
 */
export class ListSettingsItem {
	public id: number = 0;
	public name: string = null;
	public settings: ListSettings2 = null;
}


/**
 * Položka navbaru
 */
export class SectionItem {
    public id: number = 0;
    public name: string = null;
    public active: boolean = false;
    public order: number = 0;
    public parentId: number = 0;
    public position: string = "left";
    public route: string = "";
    public scopePath: string = "";
    public childExists: boolean = false;
}

/**
 * Definice buttonu v posledním sloupci seznamů 
 **/
export class DataTableOption {
	icon: string | null;
	className: string | null;
	onClick: (item: any) => void;
	tooltip: string;
	permission: ScopePermissionType | null;
}